export const imgs = [
  {
    url: '/assets/img/home/feves.png',
    description: 'Fèves de cacao Trinitario',
  },
  {
    url: '/assets/img/home/cabosses1.png',
    description: 'Cabosse de cacao Trinitario',
  },
  {
    url: '/assets/img/home/cabosses2.png',
    description: 'Cabosse de cacao Trinitario',
  },
  {
    url: '/assets/img/home/cacaoyer.png',
    description: 'cacaoyer Trinitario',
  },
  {
    url: '/assets/img/home/cabosse.png',
    description: 'Cabosse de cacao Trinitario',
  },
];
