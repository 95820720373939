import $ from 'jquery';
import Swal from 'sweetalert2';
import EmailForm from './EmailForm';
import { isNil } from 'lodash';
import { interval } from 'rxjs';

import { imgs } from './data';

$(document).ready(function () {
  let name = document.getElementById('floatingName') as HTMLInputElement;
  let mail = document.getElementById('floatingEmail') as HTMLInputElement;
  let phone = document.getElementById('floatingPhone') as HTMLInputElement;
  let body = document.getElementById('floatingTextarea') as HTMLTextAreaElement;
  let homeCrousel = document.getElementById('home-carousel') as HTMLDivElement;
  let inc = 0;

  $('#navbar').css('background-color', 'rgba(246,237,231, 0.7)');

  /**
   * Change background color navigation on scroll
   */
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll! > 100) {
      $('#navbar').css('background-color', 'rgba(246,237,231, 0.95)');
    } else {
      $('#navbar').css('background-color', 'rgba(246,237,231, 0.7)');
    }
  });

  /**
   * Home carousel
   */
  if (!isNil(homeCrousel)) {
    console.log('HOME CAROUSEL: ', $('#home-carousel'));

    const o = Math.floor(Math.random() * (imgs.length - 1));

    console.log('Random: ', o);

    $('.landscap-fluid').css({
      backgroundImage: `linear-gradient(to bottom, rgba(139, 139, 139, 0.20), rgba(18, 42, 66, 0.60)), url('${imgs[o].url}')`,
    });

    // interval(10000).subscribe({
    //   next: (val) => {
    //     if (inc < imgs.length) {
    //       console.log(imgs[inc].url);
    //       $('.landscap-fluid').css({
    //         backgroundImage: `linear-gradient(to bottom, rgba(139, 139, 139, 0.20), rgba(18, 42, 66, 0.60)), url('${imgs[inc].url}')`,
    //       });
    //       inc++;
    //     } else {
    //       inc = 0;
    //     }
    //     console.log(inc);
    //   },
    // });
  }

  /**
   * Send email
   */
  $('#btn-send').on('click', function (event) {
    event.preventDefault();
    const email = new EmailForm({ Name: name.value, Email: mail.value, Phone: phone.value, Body: body.value });
    if (email.validateEmail()) {
      fetch('https://ets-theodule.mg/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          Name: email.Name,
          EmailAddress: email.Email,
          PhoneNumber: email.Phone,
          BobyMessage: email.Body,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          Swal.fire({
            title: 'Félicitation',
            text: 'Votre message à bien été envoyé',
            icon: 'success',
          });
          resetForm();
        })
        .catch((err) => {
          Swal.fire({
            title: 'Oop!s',
            text: "Une erreur s'est produite lors de l'envoie de Votre message.",
            icon: 'error',
          });
        });
    } else {
      Swal.fire({
        title: 'Oop!s',
        text: 'Merci de vérifier votre adresse e-mail.',
        icon: 'warning',
      });
    }
  });

  /**
   * Reset email form
   */
  const resetForm = () => {
    name.value = '';
    mail.value = '';
    phone.value = '';
    body.value = '';
  };
});
