export default class EmailForm {
  public Name: string;
  public Email: string;
  public Phone: string;
  public Body: string;

  constructor(obj: any) {
    this.Name = obj.Name;
    this.Email = obj.Email;
    this.Phone = obj.Phone;
    this.Body = obj.Body;
  }

  /**
   * Validate email address
   * @returns boolean
   */
  public validateEmail(): boolean {
    const regEx: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (this.Email === null || this.Email === undefined) return false;

    return regEx.test(this.Email);
  }
}
